import React, { Fragment } from 'react';
// import { Link } from 'gatsby';
import Layout from '../components/layoutProfile';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
// import 'react-vertical-timeline-component/style.min.css';
import mng from '../images/ob/interview_iwase.jpg';
import ishizaka from '../images/map/ishizaka.png';
import ishizakaImg from '../images/map/ishizakaImg.jpg';
// import ishizakaTop from '../images/ob/ob_ishizaka_top.jpg';
// import * as ROUTES from '../src/constants/routes';

class IshizakaPage extends React.Component {

  render() {
    return (
      <Fragment>
        <title>地方部員インタビュー　石坂悠月|國學院大學久我山高等学校　バスケットボール部</title>
      <h2 className="content-title-black-ob">地方部員インタビュー</h2>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: 'black', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
        <h4 className="vertical-timeline-element-subtitle">2年 岩瀬</h4>
          <div className="mng-text">
          國學院大學久我山高校の入学理由を教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'black'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <h4 className="vertical-timeline-element-subtitle">3年 石坂悠月</h4>
          <div className="ob-text">
          はい。まず、勉強面では自分の将来に繋がりやすいと感じた事が挙げられます。高校を卒業して、どのような大学を目指すのか、自分が何をしたいのかが見つけやすいと思いました。部活面においては、東京でトップレベルの学校と知り、全国レベルのバスケットボールを体験したかったので入学を希望しました。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。では次に國學院大學久我山高校に入学して良かったことを教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          久我山高校に入学して良かったことは、他の学校には中々無い文武両道の精神が学校の文化としてある事です。毎日、19時には完全下校といった短い時間での練習と、早く帰宅してからの自宅学習を徹底しています。部活動では、トップレベルのバスケットボールを体験することができ、短い練習時間の中で質の良い練習の仕方を学ぶ事ができています。また、隙間時間も有効的に使い、無駄の無い行動をする習慣が身につきました。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          文武両道の精神は確かに学校全体に浸透してますよね。では次に久我山高校バスケ部の雰囲気を教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          はい。部活の時は短時間でみんながとても集中していて、切り替えも早くメリハリがあるので、とても良い雰囲気だと思います。また、バスケットボール以外でも荷物の整理整頓、ベンチの綺麗さでは他の学校より自信があり、そういった所も久我山高校の良い雰囲気に繋がっていると思います。

        </div>
        </VerticalTimelineElement>


        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          切り替えやメリハリがしっかりあり、それが各人身につくというのは久我山高校バスケ部の大きな特徴かもしれませんね！
          続いて、石坂さんは愛知県の中学校に通われていた、との事ですが、東京にある久我山高校に入学する上で、不安だった事ありますか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          自分は、県外から東京に来て国体に選ばれたのですが、東京でバスケットボールをするのは初めてだったので、初めはチームに上手く馴染めるか心配でした。しかし、先輩やコーチの方々が本当に上手く馴染めるように気遣ってくれた事もあり、とても接しやすくすぎにチームに溶け込むが事が出来ました。また、国体においても、東京都は他県と異なり、様々な高校の選手でチームを形成するので、貴重な体験ができました。改めて東京の高校に来て良かったと思います。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
        >
          <div className="ob-text">
          <img className="obImg" alt="sakai" src={ishizakaImg} />
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。地方からという事で色々と不安な点があったと思いますが、石坂さんは上手く成長機会として捉えていったようですね！ちなみに、愛知県出身ですが、國學院久我山高校への通学はどのようにされているのですか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
自分は、元々祖父母の家が東京にあった事もあり、高校生活の間だけ祖父母の家に住まわせて頂いているので、そこから久我山高校に通学する事ができています。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          そうなのですね！では最後に、久我山高校バスケ部に興味を持っている中学生に向けてメッセージを下さい！
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          國學院大学久我山高校は東京で間違いなくトップレベルの学校です。それは、部活動面だけでなく勉強面においても他の学校とは違い、高いレベルで実践することが出来ます。それはつまり、将来の幅が広がるのと、新しい視点で物事を見れるようになり、今までとは違った生活を送ることが出来る！と私は思っています。久我山高校バスケ部にぜひ入部してもらい、やる時はやる、など切り替えを大事にし、一緒に全国の高みを目指しましょう！
        </div>
        </VerticalTimelineElement>

      </VerticalTimeline>
    </Fragment>
);
}
}

export default () => (
  <Layout>
  <IshizakaPage />
  </Layout>
);
